












































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Company from '@/models/Company';
import { companyStoreActions, companyStoreGetter } from '@/store/company.store';
import { applicationStoreMutations } from '@/store/application.store';
import { ROUTE_DASHBOARD } from '@/router/routes';

const CompanyStore = namespace('company');
const ApplicationStore = namespace('application');

@Component({
    components: {
        CompanyTableComponent: () => import(
            /* webpackChunkName: "CompanyTableComponent" */
            '@/components/CompanyTable.component.vue'),
        EditCompanyComponent: () => import(
            /* webpackChunkName: "EditCompanyComponent" */
            '@/components/EditCompany.component.vue')
    }
})
export default class CompaniesView extends Vue {
    @CompanyStore.Action(companyStoreActions.LIST)
    public loadAllCompanies!: (payload: { withDeleted: boolean }) => Promise<Company[]>;

    @CompanyStore.Getter(companyStoreGetter.COMPANIES)
    public allCompanies!: Company[];

    public filteredCompanies: Company[] = [];

    @ApplicationStore.Mutation(applicationStoreMutations.SET_COMPANY)
    public saveSelectedCompany!: (company: Company) => void;

    public searchValue: string = '';
    public showCreationDialog: boolean = false;

    public showDeletedCompanies: boolean = false;

    public created() {
        this.loadAndFilterCompanies();
    }

    public async loadAndFilterCompanies() {
        await this.loadAllCompanies({ withDeleted: this.showDeletedCompanies });
        this.filterCompanies();
    }

    @Watch('allCompanies', { immediate: true })
    public allCompaniesChanged() {
        this.filterCompanies();
    }

    public companySelected(company: Company) {
        this.saveSelectedCompany(company);
        this.$router.push({ name: ROUTE_DASHBOARD });
    }

    public async closeCreationDialog(reload: boolean) {
        this.showCreationDialog = false;
        if (reload) {
            await this.loadAllCompanies({ withDeleted: this.showDeletedCompanies });
        }
    }

    public filterCompanies() {
        if (this.showDeletedCompanies) {
            this.filteredCompanies = this.allCompanies;
        } else {
            this.filteredCompanies = this.allCompanies.filter(company => !company.isDeleted);
        }
    }
}
